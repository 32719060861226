import { gameUtils, getTimestamp, user as userUtils } from '../../utils';
import api from '../../utils/api';
import { getPortal } from '../../utils/portals';
import {
  addIdsFavorite,
  addIdsFriend,
  addIgnoredIdsUser,
  errorLog,
  fetchedDialogLog,
  getOnlineStatus,
  noDialogLog,
  ratatoskr,
  removeDialog,
  selectDialog,
  timerOnlineStatus,
} from '../';
import { ADD_DIALOGS } from '../actions';
export default () => async (dispatch, getState) => {
  if (!getState().user?.account?.id) {
    return;
  }
  const performance = {
    fetchDialogStartTime: null,
    dialogFetchedTime: null,
    dialogCount: 0,
    onlineStateFetchedTime: null,
    onlineCount: 0,
    friendFetchedTime: null,
    friendCount: 0,
    friendAddedTime: null,
    favoriteFetchedTime: null,
    favoriteCount: 0,
    favoriteAddedTime: null,
    flagsAddedTime: null,
    ignoredCount: 0,
    ignoredAddedTime: null,
    ignoredFetchedTime: null,
  };
  try {
    performance.fetchDialogStartTime = getTimestamp();
    const response = await api.get(`/user/dialogs`);
    if (!getState().user?.account?.id) {
      return;
    }
    performance.dialogCount = response?.data?.length;
    performance.dialogFetchedTime = getTimestamp();
    await dispatch(getOnlineStatus());
    performance.onlineStateFetchedTime = getTimestamp();
    performance.onlineCount = getState().onlineStatus?.online.length;

    let friendIds = [];
    let favoriteIds = [];
    let ignoredIds = [];
    try {
      const friendResponse = await api.get(`/user/friends/ids`);
      performance.friendFetchedTime = getTimestamp();
      performance.friendCount = friendResponse?.data?.length;
      if (!getState().user?.account?.id) {
        return;
      }
      if (friendResponse?.data?.length > 0) {
        friendIds = friendResponse.data.map((el) => el.replace('-', '@'));
        dispatch(addIdsFriend(friendIds));
      }
    } catch (err) {
      console.log({ fetchFriendIdsError: err });
    }
    performance.friendAddedTime = getTimestamp();
    try {
      const favoriteResponse = await api.get(`/user/favorites/ids`);
      performance.favoriteFetchedTime = getTimestamp();
      performance.favoriteCount = favoriteResponse?.data?.length;
      if (!getState().user?.account?.id) {
        return;
      }
      if (favoriteResponse?.data?.length > 0) {
        favoriteIds = favoriteResponse.data.map((el) => el.replace('-', '@'));
        dispatch(addIdsFavorite(favoriteIds));
      }
    } catch (err) {
      console.log({ fetchFavoriteIdsError: err });
    }
    performance.favoriteAddedTime = getTimestamp();
    try {
      const ignoredResponse = await api.get(`/user/ignored`);

      performance.ignoredFetchedTime = getTimestamp();
      performance.ignoredCount = ignoredResponse?.data?.data?.length;
      ignoredIds = ignoredResponse?.data?.data.map((user) =>
        user.id.replace('-', '@')
      );
      dispatch(addIgnoredIdsUser(ignoredIds));
    } catch (err) {
      console.log({ fetchIgnoredIdsError: err });
    }
    performance.ignoredAddedTime = getTimestamp();

    const dialogs = [];
    for (let i = 0; i < response.data.length; i++) {
      if (response.data[i]?.partner) {
        const partner = response.data[i]?.partner;
        const dialogProductIdArray = partner.split('@');
        const productId =
          dialogProductIdArray?.length === 2
            ? parseInt(dialogProductIdArray[0], 10)
            : null;
        const name = response.data[i]?.flags.includes('is_support')
          ? userUtils.getAdminName(response.data[i]?.name)
          : response.data[i]?.name;

        let duplicateName = false;
        for (let j = 0; j < dialogs.length; j++) {
          if (dialogs[j].name?.toLowerCase() === name?.toLowerCase()) {
            dialogs[j].duplicateName = true;
            duplicateName = true;
          }
        }
        let portals = {};
        if (productId) {
          try {
            portals = await getPortal(productId);
          } catch (err) {
            console.log('portal not found');
          }
        }
        const dialog = {
          ...response.data[i],
          type: 'dialog.fetched',
          isDialogUser: true,
          productId,
          name,
          profile: {
            info: {
              largeLoaded: false,
              nickname: name,
              whitelabelLong: portals?.whitelabelLong,
              whitelabelShort: portals?.whitelabelShort,
            },
            turnover: {},
          },
          text: '',
          active: false,
          id: partner,
          userId: partner,
          duplicateName,
          visible: true,
          portals: {},
        };

        if (getState().onlineStatus.online.includes(dialog.partner))
          dialog.flags.push('online');

        dialog.flags = userUtils.checkFlags(
          dialog.flags,
          'friend',
          friendIds.includes(dialog.partner)
        );
        dialog.flags = userUtils.checkFlags(
          dialog.flags,
          'favorite',
          favoriteIds.includes(dialog.partner)
        );
        if (!userUtils.gamesBlocked(getState())) {
          const game = gameUtils.getGame(getState(), dialog.partner);
          dialog.flags = userUtils.checkFlags(
            dialog.flags,
            'game',
            gameUtils.isActiveGame(game)
          );

          dialog.flags = userUtils.checkFlags(
            dialog.flags,
            'gameTurn',
            gameUtils.isActiveTurn(game)
          );
          dialog.flags = userUtils.checkFlags(
            dialog.flags,
            'gameInvitation',
            gameUtils.isActiveInvitation(game)
          );
          dialog.flags = userUtils.checkFlags(
            dialog.flags,
            'gameDecided',
            gameUtils.isDecidedGame(game)
          );
          if (game) {
            const gameTimestamp = gameUtils.lastUpdatedTimestamp(game);
            if (gameTimestamp > dialog.time) {
              dialog.time = gameTimestamp;
            }
          }
        }
        if (ignoredIds.includes(response.data[i]?.partner)) {
          dispatch(removeDialog(response.data[i]?.partner));
        } else {
          dialogs.push(dialog);
        }
      }
    }
    performance.flagsAddedTime = getTimestamp();
    dispatch(fetchedDialogLog(performance));
    dispatch({
      type: ADD_DIALOGS,
      payload: dialogs,
    });
    if (dialogs.length) {
      const selectId =
        getState().dialog.savedUrlUserId ||
        getState().dialog.savedDialogId ||
        (dialogs[0] && dialogs[0].id);

      if (selectId && !ignoredIds.includes(selectId)) {
        dispatch(selectDialog({ id: selectId }));
      }
    } else {
      dispatch(noDialogLog(performance));
    }

    if (!getState().camUserFilter.ratatoskrLoaded) {
      dispatch(ratatoskr.open());
    }
    if (!getState().onlineStatus.timerRunning) {
      dispatch(timerOnlineStatus.start());
    }
  } catch (error) {
    const errorObject = {
      location: 'fetchedAllDialog',
      error,
    };
    dispatch(errorLog(errorObject));
    console.log({ fetchAllError: error });
  }
};
