export default {
  CONTEST_LIVECAM_SHOWS: 'Deine Coins aus Livecam Shows',
  CONTEST_MESSAGES: 'Deine Coins aus Nachrichten',
  CONTEST_ITEMS_SOLD: 'Deine Coins aus verkauftem Content',
  CONTEST_INFO_LINK_TEXT: 'Alle Infos findest Du hier!',
  CONTEST_UPDATED_TEXT: 'Deine Platzierung wird alle 10 Minuten aktualisiert.',
  CONTEST_RANK: 'Platz',
  CONTEST_COINS: 'Coins',
  CONTEST_NO_RANK: 'Du bist leider noch nicht in den Top 100',
  CONTEST_END_NO_RANK: 'Du hast es leider nicht in die Top 25 geschafft',

  CONTEST_WIKI_HEADLINE: 'Der große LiveCreator Herbst Contest',
  CONTEST_WIKI_INTRO_1:
    'Wir suchen die besten Livecam Girls und die besten Chatter',
  CONTEST_WIKI_INTRO_2:
    'Wir veranstalten in der Zeit <span class="bold">vom 01. bis zum 07. Oktober 2024</span> einen <span class="bold">Creator Wettbewerb</span>, bei dem Du Dich auszeichnen kannst!',

  CONTEST_WIKI_INFO_HEADLINE_1: `Was für Dich dabei herausspringt`,
  CONTEST_WIKI_INFO_TEXT_1: `Gehörst Du zu den besten Creatorn, dann erwarten Dich folgende Prämien:<br />
    <table class="has-border noHover table300">
       <tr>
          <td>Platz 1</td>
          <td class="align-right">500 € Prämie</td>
       </tr>
       <tr>
          <td>Platz 2</td>
          <td class="align-right">250 € Prämie</td>
       </tr>
       <tr>
          <td>Platz 3</td>
          <td class="align-right">150 € Prämie</td>
       </tr>
       <tr>
          <td>Platz 4 bis 10</td>
          <td class="align-right">100 € Prämie</td>
       </tr>
       <tr>
          <td>Platz 11 bis 25</td>
          <td class="align-right">50 € Prämie</td>
       </tr>
     </table>`,
  CONTEST_WIKI_INFO_TEXT_2: `Und das Beste ist: <span class='bold'>Du kannst Dich in beiden Kategorien auszeichnen</span>! Wirst Du z.B. der beste Livecam Creator (Platz 1) und die drittbeste Chatterin (Platz 3), dann bekommst Du insgesamt eine Prämie von 650 €!`,
  CONTEST_WIKI_INFO_HEADLINE_2: `Wie funktioniert das?`,
  CONTEST_WIKI_INFO_TEXT_3: `Wir werden im gesamten Aktionszeitraum die Umsätze aller Creator in den Kategorien Livecams und Chat genaustens beobachten und vergleichen. Erzielst Du Coin-Umsätze in einer der beiden Kategorien, werden diese automatisch erfasst und Du wirst in der Rangliste für die entsprechende Kategorie platziert.`,
  CONTEST_WIKI_INFO_TEXT_4: `Über Deine aktuelle Platzierung wirst Du auf Deinem Dashboard informiert. Bist Du in einer Kategorie unter den 100 besten Creatorn, wird Dir dies nochmals gesondert angezeigt.`,
  CONTEST_WIKI_INFO_HEADLINE_3: `Was Du tun musst, um mitzumachen`,
  CONTEST_WIKI_INFO_TEXT_5: `Online sein! 😊 Alle Creator, die sich während des Aktionszeitraums in LiveCreator einloggen, nehmen automatisch am Wettbewerb teil. Und wer am meisten Coins macht, gewinnt!`,
  CONTEST_WIKI_INFO_TEXT_6: `Folgende Umsätze fließen in die Kategorien ein:<br />
    <ul class='checklist'>
      <li><span class='bold'>Livecams:</span> alle Coins aus den Minuten der Livecam-, Exklusiv- und Voyeur-Shows und ToyControl</li>
      <li><span class='bold'>Nachrichten:</span> alle Coins aus Verkäufen von kostenpflichtigen Nachrichten, Voice Messages, Sexicons und Community Games. Aufmerksamkeiten und Premium Dateianhänge sind ausgeschlossen</li>
    </ul>`,
  CONTEST_WIKI_INFO_TEXT_7: `Die Aktion beginnt am 01. Oktober 2024 um 00.00 Uhr und endet am 07. Oktober 2024 um 24.00 Uhr. Sobald die Aktion beendet ist, wird Dir der vorläufige Endstand dargestellt. Deine Prämie erhältst Du dann mit der Auszahlung am 16. Oktober 2024.`,
  CONTEST_WIKI_INFO_TEXT_8: `Also sei dabei und zeig, was Du kannst!`,
  CONTEST_WIKI_INFO_TEXT_9: `Wir wünschen Dir viel Erfolg!`,
};

