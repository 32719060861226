import activities from './activities';
import alerts from './alerts';
import countryNames from './countryNames';
import settings from './settings';

export default {
  ...countryNames,
  ...alerts,
  ...settings,
  ...activities,

  language: 'Sprache',
  error: 'Fehler',
  accept: 'Bestätigen',
  cancel: 'Abbrechen',
  'streamingButton.start': 'Stream starten',
  'streamingButton.startEvent': 'Event starten',
  'streamingButton.stop': 'Stream stoppen',
  'streamingButton.stopEvent': 'Event stoppen',
  'streamingButton.isLoading': 'Lädt...',
  mottoHeadline: 'Füge ein Motto für Deine Cam Show hinzu',
  mottoLabel: 'Mein heutiges Motto ist:',
  mottoPlaceholder: 'Kein Motto',
  'login.password': 'Kennwort',
  'login.callSupport':
    'Anmeldung nicht möglich. Bitte wende Dich an unseren Support.',
  'login.noUsername': 'Dieser Benutzername ist nicht vorhanden',
  'login.wrongPassword': 'Das Login-Kennwort ist falsch',
  'login.reload':
    'Die Software Version ist nicht aktuell. Die Seite wird neu geladen.',
  'login.cookieHint':
    'Die Software benötigt Cookies. Bitte aktiviere alle Cookies in Deinem Browser.',
  'login.cookie3rdHint':
    'Um Livecam Streaming zu nutzen, musst Du alle Cookies inklusive den Drittanbieter Cookies aktivieren',
  'login.livecamErrorHint':
    'Die Livecam ist momentan nicht verfügbar. Bitte probiere es später noch einmal.',
  'chat.translated': 'übersetzt',
  'chat.kiss': 'Ich küsse Dich',
  'chat.game.invitation': 'Spiel mit mir',
  'chat.game.invitationTimeout': 'Die Einladung ist abgelaufen',
  'chat.game.invitationDeclined': 'Du hast die Einladung abgelehnt',
  'chat.game.draw': 'Das Spiel ging unentschieden aus',
  'chat.game.winByTimeout': 'Du hast das Spiel durch einen Timeout gewonnen',
  'chat.game.lostByTimeout': 'Du hast das Spiel durch einen Timeout verloren',
  'chat.game.winByTurn': 'Du hast das Spiel gewonnen',
  'chat.game.lostByTurn': 'Du hast das Spiel verloren',
  'chat.game.invitation.single': 'Spiel mit mir',
  'chat.game.invitationTimeout.single':
    'Die Einladung von {user} ist abgelaufen',
  'chat.game.invitationDeclined.single':
    'Du hast die Einladung von {user} abgelehnt',
  'chat.game.draw.single': 'Das Spiel gegen {user} ging unentschieden aus',
  'chat.game.winByTimeout.single':
    'Du hast das Spiel gegen {user} durch einen Timeout gewonnen',
  'chat.game.lostByTimeout.single':
    'Du hast das Spiel gegen {user} durch einen Timeout verloren',
  'chat.game.winByTurn.single': 'Du hast das Spiel gegen {user} gewonnen',
  'chat.game.lostByTurn.single': 'Du hast das Spiel gegen {user} verloren',
  'chat.match': 'Wir haben ein Match',
  'chat.regard': 'Du hast eine Aufmerksamkeit erhalten im Wert von ',
  'chat.regardCoins': '{coins} Coins',
  'chat.icebreaker': 'Icebreaker',
  'chat.datingAnimation': 'Dating Animation',
  'chat.payttachment':
    'Du hast einen kostenpflichtigen Dateianhang gesendet im Wert von <span class="price">{price} Coins</span>',
  'chat.payttachment.loading':
    'Du hast einen kostenpflichtigen Dateianhang gesendet',

  'account.system': 'System',
  'title.livecam': 'Livecam',
  'title.voyeur': 'Voyeure:',
  'title.messenger': 'Messenger',
  'voyeur.tooltip': 'Du sendest im Voyeur Modus',
  'tip.text': 'Trinkgelder:',
  'tip.textusercount': 'User:',
  'systemMessage.loggedIn':
    'Verbindung zum Livecam Server hergestellt. Warte auf User.',
  'systemMessage.loggedOut':
    'Die Verbindung zum Livecam Server wurde beendet. Bitte klicke "Stream starten" um eine neue Verbindung aufzubauen.',
  'systemMessage.userJoined': 'User {user} hat Deine Show betreten',
  'systemMessage.userJoinedTranslate':
    'Dieser User spricht Deine Sprache nicht. Der AC Translator übersetzt eure Nachrichten automatisch.',
  'systemMessage.userLeft': 'User {user} hat den Chat verlassen.',
  'systemMessage.exclusiveError':
    'Achtung! Fehler beim Wechsel in den Exklusiv Modus.',
  'systemMessage.welcome':
    'Bitte klicke "Stream starten" um eine Verbindung zum Livecam Server aufzubauen.',
  'systemMessage.startExclusive': 'Die Exklusiv Show wurde gestartet',
  'systemMessage.exclusiveDenied':
    '{user} hat Deine Exklusiv Show Anfrage abgelehnt.',
  'systemMessage.tip': '{user} spendiert Dir {amount} Coins Trinkgeld.',
  'systemMessage.kickUser': 'User wurde von einem Admin gekickt',
  'control.send': 'senden',
  'control.sendTooltip': 'Nachricht an den momentan ausgewählten User senden',
  'control.2all': '2all',
  'control.2allTooltip': 'Nachricht an alle User senden',
  'control.smiley': 'smiley',

  'streamingInfo.resolution': 'Auflösung:',
  'streamingInfo.upload': 'Upload:',
  'streamingInfo.upload.value': '{bandwidth} kbit/s',
  'streamingInfo.fps': 'Frames/s:',
  'streamingInfo.fpsHint':
    'Die Leistung Deines Computers und/oder Deiner Internetverbindung sind nicht ausreichend. Bitte schließe alle nicht genutzten Programme und überprüfe die Streaming Optionen. Hilft das nicht, kontaktiere bitte unseren Support.',

  'userlist.exclusive': 'Exklusiv',
  'userlist.info': 'User Info',
  'userlist.mic': 'User Mic',
  'userlist.micOff': 'User Mic Off',
  'userlist.note': 'User Note',
  'userlist.ban': 'User Ban',
  'user.origin': 'User kommt aus {country}',
  'user.exclusiveShowTooltip':
    'Eine Exklusiv Show kostet den User {price} Coins pro Minute',

  'usernote.errorPutNotes': 'Die Notizen konnten nicht abgespeichert werden',
  'usernote.errorGetNotes': 'Die Notizen konnten nicht abgerufen werden',
  'btn.title.kickUser': 'Aus meiner Cam kicken',
  'btn.title.ignoreUser': 'Ignorieren',
  'btn.title.userSoundOn': 'User-Audio an',
  'btn.title.userSoundOff': 'User-Audio aus',
  'btn.title.askExclusive': '{username} zu einer Exklusiv Show einladen',
  'btn.title.askExclusiveBlank': 'Zu einer Exklusiv Show einladen',
  'btn.user.info': 'Info',
  'btn.user.notes': 'Notizen',
  'btn.user.connect4': '4score',
  'btn.title.chatMulti': 'Ein Chat für alle Livecam User',
  'btn.title.chatSingle': 'Bubble Chat verwenden',
  'btn.title.sortAsc': 'Sortierung von Alt zu Neu',
  'btn.title.sortDesc': 'Sortierung von Neu zu Alt',
  'btn.title.allTime': 'Gesamter Zeitraum',

  'controlPanel.exclusiveTooltip':
    'Hier aktivierst Du den Exklusiv Modus. ACHTUNG: Im Exklusiv Modus wirst Du nicht mehr auf den Partnerseiten angezeigt! Alle aktiven Shows werden beendet!',
  reset: 'Zurücksetzen',

  'search.for.nickname': 'Nach Nickname suchen',
  'chat.input.placeholder': 'Schreib eine Nachricht',
  'no.user': 'Kein User vorhanden',
  'user.isSupport':
    'Dieser User ist ein offizieller Administrator. Sei nett zu ihm :)',

  'user.age': 'Alter:',
  'user.age.years': 'Jahre',
  'user.gender': 'Geschlecht:',
  'user.country': 'Herkunft:',
  'user.language': 'Sprache:',
  'user.is.typing': 'schreibt...',

  'feature.headline':
    'Folgende kostenpflichtige Features stehen diesem User zur Verfügung:',
  'feature.exclusive': 'Besuch Deiner Exklusiv Camshow',
  'feature.toycontrol': 'Nutzung Deiner Toycontrol Spielzeuge',
  'feature.game': 'Gemeinsam mit Dir Community Games spielen',
  'feature.galleries': 'Kauf Deiner Bilder und Videos',
  'feature.voiceMessage': 'Empfang und Senden von Sprachnachrichten',
  'feature.attachments': 'Empfang und Senden von Dateianhängen',
  'feature.payttachments': 'Empfang Deiner Premium Dateianhänge',
  'feature.exclusive.unavailable': 'Exklusive Camshow ist nicht verfügbar',
  'feature.toycontrol.unavailable':
    'Toycontrol Spielzeuge sind nicht verfügbar',
  'feature.game.unavailable': 'Community Games sind nicht verfügbar',
  'feature.galleries.unavailable':
    'Kauf Deiner Bilder und Videos ist nicht verfügbar',
  'feature.voiceMessage.unavailable': 'Sprachnachrichten sind nicht verfügbar',
  'feature.attachments.unavailable':
    'Empfang und Senden von Dateianhängen ist nicht verfügbar',
  'feature.payttachments.unavailable':
    'Premium Dateianhänge sind nicht verfügbar',

  'user.turnover': 'User Umsätze',
  'turnover.cam': 'Livecam',
  'turnover.cam.tip': 'Livecam Trinkgeld',
  'turnover.chat': 'Private Nachricht',
  'turnover.premium.attachments': 'Premium Dateianhang',
  'turnover.regards': 'Aufmerksamkeit',
  'turnover.sexicons': 'Sexicon',
  'turnover.video': 'Video',
  'turnover.photo': 'Foto',
  'turnover.games': 'Community Game',
  'turnover.voice.messages': 'Voice Message',
  'not.specified': 'keine Angabe',

  'user.personal': `Persönliches über mich`,
  'user.height': `Körpergröße:`,
  'user.weight': `Gewicht:`,
  'user.figure': `Figur:`,
  'user.eyeColor': `Augenfarbe:`,
  'user.hairColor': `Haarfarbe:`,
  'user.hairLength': `Haarlänge:`,
  'user.bodyHair': `Körperbehaarung:`,
  'user.pubicHair': `Schambehaarung:`,
  'user.penisLength': `Länge meines erigierten Penis:`,
  'user.penisGirth': `Umfang meines erigierten Penis:`,
  'user.piercings': `Piercings:`,
  'user.ethnicity': `Typ:`,
  'user.maritalStatus': `Familienstand:`,
  'user.tatoos': `Tattoos:`,
  'user.glasses': `Brillenträger:`,
  'user.smoking': `Raucher:`,

  'user.preferences': `Meine Vorlieben`,
  'user.turnsOn': `Was ich suche, ist`,
  'user.experience': `Was ich erleben will, ist`,
  'user.lookingFor': `Was mich antörnt, ist`,

  'user.moreInfo': `Mehr über mich`,
  'user.eroticFantasy': `Meine erotischste Fantasie`,
  'user.iSay': `Was ich über mich sagen kann`,
  'user.otherSay': `Was über mich gesagt wird`,

  male: 'männlich',
  female: 'weiblich',
  'no.result': 'Keine Ergebnisse für "{camUserFilter}"',
  'new.user': 'Neu',

  'tooltip.normalShow':
    'Zeigt an, dass ein User Deine normale Show betreten kann',
  'tooltip.voyeurShow':
    'Zeigt an, dass ein User Deine Show als Voyeur betreten kann',
  'tooltip.exclusiveShow':
    'Zeigt an, dass ein User mit Dir eine Exclusive Show hat',

  I: 'Ich',
  me: 'Mich',
  'btn.title.showMyCam': 'Zeige meine Cam',
  'btn.title.showCam2cam': 'Zeige die Cam von {username}',

  'label.noUserSelected': 'Es ist kein User ausgewählt',

  showMore: 'Mehr',
  back: 'Zurück',

  userStateIgnored:
    'Es sieht so aus, als ob Du blockiert wurdest von {nickname}. ',
  userStateDeleted: 'Es sieht so aus, als ob {nickname} gelöscht wurde. ',
  userIgnored: 'Es sieht so aus, als hättest Du {nickname} ignoriert. ',
  userUnignore:
    'Möchtest du den User nicht länger ignorieren oder diesen Dialog löschen?',
  deleteDialog: 'Möchtest Du diesen Dialog löschen?',
  deleteDialogButton: 'Ja, bitte',

  messageNotSend: 'Die Nachricht konnte nicht gesendet werden. ',
  sendAgain: 'Erneut Versuchen?',
};

