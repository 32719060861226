import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { sound } from '../../../actions';
import menuIcon from '../../../assets/img/svg/menu-dots.svg';
import { DEFAULT_SOUNDS } from '../../../constants/sound';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { roomType as roomTypeUtils } from '../../../utils';
import Button from '../../Button/Button';
import AudioVolume from '../AudioVolume';
import LeaveShow from './LeaveShow';
import LostConnection from './LostConnection';
import NewMessage from './NewMessage';

const SoundSelect = ({
  reset,
  isEvent,
  lostConnectionSoundDefault,
  leaveShowSoundDefault,
  newMessageSoundDefault,
  volumeDefault,
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef();
  const menuIconRef = useRef();

  useOutsideClick(
    menuRef,
    () => {
      if (menuVisible) {
        setMenuVisible(false);
      }
    },
    menuIconRef
  );

  const menuIconClass = classNames('menu-icon', {
    active: menuVisible,
  });

  const disabled =
    lostConnectionSoundDefault &&
    leaveShowSoundDefault &&
    newMessageSoundDefault &&
    volumeDefault;

  return (
    !isEvent && (
      <div className="group-container">
        <div className="headline-with-menu form-group">
          <h2 className="headline column">
            <FormattedMessage id="audio.title" />
          </h2>
          <div className="settings-menu-wrapper">
            <Button
              variant="icon-only"
              onClick={() => {
                if (!menuVisible) {
                  setMenuVisible(true);
                }
              }}
              intlTranslate={false}
              icon={menuIcon}
              className={menuIconClass}
              disabled={disabled}
            />
            {menuVisible && (
              <div className="toggle-menu" ref={menuRef}>
                <Button
                  variant="default"
                  className="menu-item"
                  label="reset"
                  onClick={() => {
                    setMenuVisible(false);
                    reset();
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <AudioVolume />
        <LostConnection />
        <LeaveShow />
        <NewMessage />
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  isEvent: roomTypeUtils.isEvent(state.sender.roomTypes),
  lostConnectionSoundDefault:
    state.preferences.sounds.lostConnection.type ===
    DEFAULT_SOUNDS.lostConnection.type,
  leaveShowSoundDefault:
    state.preferences.sounds.leaveShow.type === DEFAULT_SOUNDS.leaveShow.type,
  newMessageSoundDefault:
    state.preferences.sounds.newMessage.type === DEFAULT_SOUNDS.newMessage.type,
  volumeDefault: state.preferences.volume === 1,
});

const mapDispatchToProps = (dispatch) => ({
  reset: () => {
    dispatch(sound.reset());
  },
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SoundSelect)
);
