import { lStorage } from '../../services/storage';
import { user as userUtils } from '../../utils';
import crypt from '../../utils/crypt';
import { camUser, management } from '..';
export default (id, oldActiveUser, isSameUser = false) =>
  async (dispatch, getState) => {
    if (!getState().user.isLoggedIn) {
      return;
    }
    const {
      camUser: { camUsers },
      dialog: { dialogs, processQueue, queue },
      user: { account: { id: creatorId } = {} },
      streaming: { permanentCam2CamStream },
      cam2cam: streams,
    } = getState();
    const userList = [...camUsers, ...dialogs, ...processQueue, ...queue];

    const user = userList.find(
      (user) => user.active && (user.userId === id || user.partner === id)
    );
    if (!user) {
      return;
    }

    if (
      oldActiveUser &&
      oldActiveUser.hasCam2Cam &&
      oldActiveUser.cam2camActive &&
      !permanentCam2CamStream &&
      id !== oldActiveUser.id
    ) {
      dispatch(management.stopCam2Cam(oldActiveUser.id));
    }

    const hasActiveCam2CamStream = !!streams[id];

    if (user.hasCam2Cam && !user.cam2camActive && !hasActiveCam2CamStream) {
      dispatch(management.startCam2Cam(id));
    }

    dispatch(camUser.fetchData(user, isSameUser));

    if (!user?.flags.includes('noRemoteDialog')) {
      let i = id;
      if (userUtils.isLivecamUserId(id)) {
        if (!userUtils.hasPartner(user)) {
          return;
        }
        i = userUtils.getPartner(user);
      }
      lStorage.setItem(creatorId, crypt.run({ i, t: Date.now() }, true));
    }
  };
