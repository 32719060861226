import { connect } from 'react-redux';

import {
  answerFriendRequest,
  deleteFriend,
  deletedStatusUser,
  dialogRead,
  friendsError,
  ignoreUser,
  knownSenderDialog,
  management,
  removeDeniedFriendRequest,
  removeDialog,
  sendFriendRequest,
  toggleUserMenu,
  toggleUserlistMenu,
  toggleUserlistView,
  camUser as userActions,
} from '../../../../actions';
import { roomType, user as userUtils } from '../../../../utils';
import UserList from './UserList';

const mapStateToProps = (state) => {
  const {
    camUser: { camUsers },
    dialog: { dialogs, queue, processQueue, firstDialogsLoaded },
    camUserFilter: { filter, messagesFilter, activities },
    sender: { roomTypes, askKickUserConfirmedAlert, askKickUserConfirmed },
    ignored: {
      askIgnoreUserConfirmed,
      askIgnoreUserConfirmedAlert,
      askIgnoreUserId,
      askIgnoreUserKick,
    },
    friends: {
      askSendFriendRequestConfirmed,
      askSendFriendRequestConfirmedAlert,
      askSendFriendRequestId,
      askAnswerFriendRequestConfirmed,
      askAnswerFriendRequestConfirmedAlert,
      askAnswerFriendRequestId,
      askAnswerFriendRequestType,
      askAnswerFriendRequestNickname,
      askDeleteFriendConfirmed,
      askDeleteFriendConfirmedAlert,
      askDeleteFriendId,
      askRemoveDeniedFriendRequestConfirmed,
      askRemoveDeniedFriendRequestConfirmedAlert,
      askRemoveDeniedFriendRequestId,
      askRemoveDeniedFriendRequestNickname,
    },
    preferences: { chatlayout, usercolor },
    cam: {
      selectedMicrophone: { value: hasMic },
    },
    view: { userlist: isUserlistOpen, userListMenuOpen },
    browser,
    user,
  } = state;

  const activeDialog = dialogs.find((d) => d.active);
  return {
    isMobile: state.view.isMobile,
    isMediumLarge: state.view.isMediumLarge,
    isMedium: state.view.isMedium,
    loading: queue.length + processQueue.length > 0 || !firstDialogsLoaded,
    camUserFilter: filter,
    camUserMessagesFilter: messagesFilter,
    camUserFiltered: userUtils.filter(
      filter,
      '',
      userUtils.visibleUser(camUsers)
    ),
    dialogUserFiltered: userUtils.filter(
      filter,
      messagesFilter,
      userUtils.visibleUser(dialogs),
      activities
    ),
    usercolor: usercolor && chatlayout === 'single',
    voyeurCount: userUtils.voyeurCount(camUsers),
    livecamCount: userUtils.livecamCount(camUsers),
    isEvent: roomType.isEvent(roomTypes),
    isExclusive: roomType.isExclusive(roomTypes),
    hasVoyeur: roomType.hasVoyeurRoomType(roomTypes),
    askKickUserConfirmed,
    askKickUserConfirmedAlert,
    askIgnoreUserConfirmed,
    askIgnoreUserConfirmedAlert,
    askIgnoreUserId,
    askIgnoreUserKick,
    askSendFriendRequestConfirmed,
    askSendFriendRequestConfirmedAlert,
    askSendFriendRequestId,
    userListMenuOpen,
    hasMic: !!hasMic,
    isUserlistOpen,
    user,
    browser,
    askAnswerFriendRequestConfirmed,
    askAnswerFriendRequestConfirmedAlert,
    askAnswerFriendRequestId,
    askAnswerFriendRequestType,
    askAnswerFriendRequestNickname,
    askDeleteFriendConfirmed,
    askDeleteFriendConfirmedAlert,
    askDeleteFriendId,
    askRemoveDeniedFriendRequestConfirmed,
    askRemoveDeniedFriendRequestConfirmedAlert,
    askRemoveDeniedFriendRequestId,
    askRemoveDeniedFriendRequestNickname,
    activeDialog,
    messagesFilter: state.camUserFilter?.messagesFilter,
    hasMessageScope: state.user.account?.scopes?.includes('send_message'),
    gamesEnabled: userUtils.gamesEnabled(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUserClick: (id) => dispatch(userActions.select(id)),
  onUserKickConfirm: (stamp) => dispatch(management.kickUser(true, stamp)),
  onUserIgnoreConfirm: (stamp, kick, id) =>
    dispatch(ignoreUser(true, stamp, kick, id)),
  toggleUserMenu: () => dispatch(toggleUserMenu()),
  filterUserlist: (value) => dispatch(userActions.filter(value)),
  toggleUserlistMenu: () => dispatch(toggleUserlistMenu()),
  toggleUserlistView: () => dispatch(toggleUserlistView()),
  removeDialog: (payload) => dispatch(removeDialog(payload)),
  friendRequestSent: () => dispatch(sendFriendRequest(true)),
  answerFriendRequest: (requestSent, userId, nickname, friendRequestType) =>
    dispatch(
      answerFriendRequest(requestSent, userId, nickname, friendRequestType)
    ),
  friendsError: () => dispatch(friendsError()),
  deleteFriend: (id, requestSent, name) =>
    dispatch(deleteFriend(id, requestSent, name)),
  removeDeniedFriendRequest: (id, name) =>
    dispatch(removeDeniedFriendRequest(id, name, true)),
  dialogRead: (id) => dispatch(dialogRead(id)),
  knownSenderDialog: (id) => dispatch(knownSenderDialog(id)),
  deletedStatusUser: (id) => dispatch(deletedStatusUser(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
