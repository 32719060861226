import './Contest.scss';

import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import BannerImageDE from '../../../../assets/img/autumncontest2024/banner_de.jpg';
import BannerImageEN from '../../../../assets/img/autumncontest2024/banner_en.jpg';
import ContestLogoDE from '../../../../assets/img/autumncontest2024/contest-logo-de.png';
import ContestLogoEN from '../../../../assets/img/autumncontest2024/contest-logo-en.png';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import ContestComponent from '../../../Contest';
import PageSection from '../../../PageSection/PageSection';

function RightColumn({ locale, isContentSectionDisplayed }) {
  return (
    <div className="right-column">
      {isContentSectionDisplayed &&
        (locale === 'en' ? (
          <img className="banner" src={BannerImageEN} alt="Autumn Contest" />
        ) : (
          <img className="banner" src={BannerImageDE} alt="Autumn Contest" />
        ))}

      <h2 className="headline">
        <FormattedMessage id="CONTEST_WIKI_INFO_HEADLINE_1" />
      </h2>
      <p>
        <FormattedMessage id="CONTEST_WIKI_INFO_TEXT_1">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="CONTEST_WIKI_INFO_TEXT_2">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <h3 className="headline">
        <FormattedMessage id="CONTEST_WIKI_INFO_HEADLINE_2" />
      </h3>
      <p>
        <FormattedMessage id="CONTEST_WIKI_INFO_TEXT_3" />
      </p>
      <p>
        <FormattedMessage id="CONTEST_WIKI_INFO_TEXT_4" />
      </p>
      <h3 className="headline">
        <FormattedMessage id="CONTEST_WIKI_INFO_HEADLINE_3" />
      </h3>
      <p>
        <FormattedMessage id="CONTEST_WIKI_INFO_TEXT_5" />
      </p>
      <p>
        <FormattedMessage id="CONTEST_WIKI_INFO_TEXT_6">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="CONTEST_WIKI_INFO_TEXT_7" />
      </p>
      <p className="bold">
        <FormattedMessage id="CONTEST_WIKI_INFO_TEXT_8" />
      </p>
      <p>
        <FormattedMessage id="CONTEST_WIKI_INFO_TEXT_9" />
      </p>
    </div>
  );
}

function Contest({ isMobile, locale, contest, showRanking }) {
  const isContentSectionDisplayed = useMediaQuery('(min-width: 1600px)');
  const state = contest?.state;
  const className = classNames('wiki-container', 'contest-container', {
    mobile: isMobile,
    [`locale-${locale}`]: !!locale,
    [`contest-${state}`]: !!state,
  });

  return (
    <PageSection
      rightColumn={
        <RightColumn
          locale={locale}
          isContentSectionDisplayed={isContentSectionDisplayed}
        />
      }
      className={className}
      containsHeader={true}
    >
      <h1 className="headline">
        <FormattedMessage id="CONTEST_WIKI_HEADLINE" />
      </h1>
      <div className="text-container">
        {locale === 'en' ? (
          <img
            className="contest-logo"
            src={ContestLogoEN}
            alt="Autumn Contest Logo"
          />
        ) : (
          <img
            className="contest-logo"
            src={ContestLogoDE}
            alt="Herbst Contest Logo"
          />
        )}
        <div className="intro-text">
          <p>
            <FormattedMessage id="CONTEST_WIKI_INTRO_1" />
          </p>
          <p>
            <FormattedMessage id="CONTEST_WIKI_INTRO_2">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
        </div>
      </div>
      {showRanking && <ContestComponent />}
      {!isContentSectionDisplayed && (
        <RightColumn
          locale={locale}
          isContentSectionDisplayed={isContentSectionDisplayed}
        />
      )}
    </PageSection>
  );
}

export default Contest;

