import './Dashboard.scss';

import useAxios from 'axios-hooks';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import affiliateIcon from '../../../assets/img/svg/affiliate.svg';
import dashboardLinkIcon from '../../../assets/img/svg/dashboard.svg';
import linkIcon from '../../../assets/img/svg/link.svg';
import {
  NOTIFICATION_MAINTENANCE,
  NOTIFICATION_NOTE,
  NOTIFICATION_WARNING,
} from '../../../constants';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { spinnerRequest } from '../../../shared/spinnerRequest';
import { isLivecamCreator, uuid } from '../../../utils';
import Button from '../../Button/Button';
import Contest from '../../Contest';
import DashboardLink from '../../DashboardLink/DashboardLink';
import Kpi from '../../Kpi';
import Notifications from '../../Notifications';
import Scrollbar from '../../Scrollbar';
import SpinnerPage from '../../Spinner/SpinnerPage';
import ArticlesCoinsTable from '../../Tables/ArticlesCoinsTable/ArticlesCoinsTable';
import ArticlesExtrasTable from '../../Tables/ArticlesExtrasTable/ArticlesExtrasTable';
import ProgressItem from '../../Tables/TableItems/ProgressItem/ProgressItem';
import DefaultBanner from '../DefaultBanner/DefaultBanner';
import Webinar from '../Webinar/Webinar';
import ArticlesMobile from './ArticlesMobile';
import {
  dashboardLinks,
  getDateProgressData,
  getProgressData,
} from './Dashboard.functions';

function Dashboard({
  user = {},
  spinner = () => {},
  isMobile,
  openOverlay,
  hasMobileOverlay,
  hasContest,
}) {
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');
  const intl = useIntl();
  const [progressData, setProgressData] = useState({});

  const isLivecamActive = isLivecamCreator(user);

  const isConfirmed = user?.account?.state === 'confirmed';

  const [
    { data: billingPeriodData, loading: billingPeriodDataLoading },
    billingPeriodRequest,
  ] = useAxios(
    {
      url: '/user/current-billing',
    },
    { manual: true }
  );

  const [{ loading: webinarLoading, data: webinarData }, webinarDataRequest] =
    useAxios(
      {
        url: '/webinar',
      },
      { manual: true }
    );

  useEffect(() => {
    spinnerRequest({
      request: webinarDataRequest,
      spinner,
    }).catch(() => {});
  }, [webinarDataRequest, spinner]);

  const revenueNotVisible =
    user?.account?.slave && !user?.account?.slave?.revenueVisible;

  useEffect(() => {
    if (revenueNotVisible) {
      return;
    }
    billingPeriodRequest()
      .then((response) => {
        setProgressData(getProgressData(response.data));
      })
      .catch(() => {});
  }, [billingPeriodRequest, revenueNotVisible, spinner]);

  const translateLinks = (links) =>
    links
      .filter((link) => {
        if (link.needsConfirmed) {
          return isConfirmed;
        }
        if (link.needsLivecam) {
          return isLivecamActive;
        }
        return true;
      })
      .map((link) => {
        return {
          ...link,
          title: intl.formatMessage({ id: link.title }),
          content: (
            <span
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: link.content,
                }),
              }}
            />
          ),
        };
      });

  const translatedDashboardLinks = {
    affiliate: translateLinks(dashboardLinks.affiliate),
    important: translateLinks(dashboardLinks.important),
  };
  const dashboardDataWrapper = classNames('dashboard-data-wrapper', {
    webinar: webinarData && Object.keys(webinarData).length > 0,
    contest: hasContest,
    unconfirmed: !isConfirmed,
  });

  return (
    <Scrollbar visible={true}>
      <div className={dashboardDataWrapper}>
        {isMobile && !hasMobileOverlay && isConfirmed && (
          <div className="overlay-button-wrapper">
            <Button
              variant="icon-only"
              icon={dashboardLinkIcon}
              intlTranslate={false}
              onClick={() => {
                const stamp = uuid();
                openOverlay({
                  stamp,
                  Component: 'DashboardMobileOverlay',
                  props: { stamp },
                });
              }}
            />
          </div>
        )}
        {isMobile ? (
          <div className="dashboard-col-wrapper notifications-warnings-wrapper">
            <div className="padding-wrapper">
              <Notifications minLevel={NOTIFICATION_WARNING} />
            </div>
          </div>
        ) : null}
        {webinarData && Object.keys(webinarData).length === 0 ? (
          <DefaultBanner isConfirmed={isConfirmed} />
        ) : (
          <Webinar
            language={user.account?.language}
            webinarData={webinarData}
            webinarLoading={isLargeScreen && webinarLoading}
          />
        )}
        {!revenueNotVisible && (
          <>
            {hasContest && !isLargeScreen && (
              <div className="dashboard-col-wrapper dashboard-contest">
                <Contest />
              </div>
            )}
            <div className="dashboard-col-wrapper articles-coins-table-wrapper">
              <Scrollbar visible={true}>
                <div className="padding-wrapper">
                  <div className="articles-coins-headline-wrapper">
                    <h2 className="headline">
                      <FormattedMessage id="DASHBOARD_CURRENT_SALES" />
                    </h2>
                    <div className="articles-coins-progress-bar">
                      {billingPeriodData && (
                        <ProgressItem
                          data={intl.formatNumber(
                            progressData.item?.next_coins
                          )}
                          percent={progressData.item?.next_percent}
                          location={'dashboard'}
                          rate={progressData.rate}
                          model={progressData.model}
                          type={'dashed'}
                          leftText={intl.formatMessage({
                            id: 'DASHBOARD_PROGRESS',
                          })}
                          rightText={`${progressData.model} ${progressData.rate}%`}
                        />
                      )}
                    </div>
                  </div>
                  {billingPeriodDataLoading ? (
                    <SpinnerPage />
                  ) : (
                    billingPeriodData && (
                      <div className="articles-coins-table">
                        <ArticlesCoinsTable
                          items={Object.values(
                            billingPeriodData?.articles.coin
                          )}
                          dateFormat={'DD/MM/YYYY'}
                          results={[
                            {
                              data: intl.formatMessage({
                                id: 'DASHBOARD_TOTAL_TURNOVER',
                              }),
                              width: '50%',
                            },
                            {
                              data: billingPeriodData?.coin_total,
                              width: '50%',
                            },
                          ]}
                          location={'dashboard'}
                          linksAvailable={true}
                        />
                      </div>
                    )
                  )}
                </div>
              </Scrollbar>
            </div>
            <div className="dashboard-col-wrapper articles-extras-table-wrapper">
              <div className="padding-wrapper">
                <div className="articles-extras-headline-wrapper">
                  <h2 className="headline">
                    <FormattedMessage id="DASHBOARD_CURRENT_CREDITS" />
                  </h2>
                  <div className="articles-extras-progress-bar">
                    {billingPeriodData && (
                      <ProgressItem
                        data={intl.formatNumber(progressData.item?.next_coins)}
                        value={'Coins'}
                        percent={getDateProgressData(progressData.start)}
                        location={'dashboard'}
                        type={'filled'}
                        leftText={dayjs(progressData.start).format(
                          user.account?.language === 'de'
                            ? 'DD.MM.YYYY'
                            : 'MM/DD/YYYY'
                        )}
                        rightText={dayjs(progressData.end).format(
                          user.account?.language === 'de'
                            ? 'DD.MM.YYYY'
                            : 'MM/DD/YYYY'
                        )}
                      />
                    )}
                  </div>
                </div>
                {billingPeriodDataLoading ? (
                  <SpinnerPage />
                ) : (
                  billingPeriodData && (
                    <div className="articles-extras-table">
                      {isMobile ? (
                        <ArticlesMobile
                          revenueTotal={billingPeriodData?.revenue_total}
                          currency={billingPeriodData?.currency}
                        />
                      ) : (
                        <ArticlesExtrasTable
                          items={Object.values(
                            billingPeriodData?.articles.extra
                          )}
                          dateFormat={'DD/MM/YYYY'}
                          results={[
                            {
                              data: intl.formatMessage({
                                id: 'DASHBOARD_CREDITS',
                              }),
                              width: '50%',
                            },
                            {
                              data: billingPeriodData?.revenue_total,
                              width: '50%',
                            },
                          ]}
                          additionalInfo={{
                            turnover: billingPeriodData?.coin_total,
                            share: billingPeriodData?.affected_rate,
                            premium: billingPeriodData?.affected_revenue,
                          }}
                          precision={2}
                          currency={billingPeriodData?.currency}
                          location={'dashboard'}
                        />
                      )}
                    </div>
                  )
                )}
              </div>
            </div>
          </>
        )}
        <div className="links-wrapper">
          <div className="dashboard-col-wrapper affiliate-links">
            <div className="padding-wrapper">
              <h2 className="headline">
                <ReactSVG
                  src={affiliateIcon}
                  wrapper="span"
                  className="link-icon"
                />
                <span>
                  <FormattedMessage id="DASHBOARD_AFFILIATE_LINKS" />
                </span>
              </h2>
              <div className="link-items-wrapper">
                {translatedDashboardLinks.affiliate.map((link, index) => (
                  <DashboardLink
                    key={`${link.title}.${index}`}
                    title={link.title}
                    content={link.content}
                    path={link.path}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="dashboard-col-wrapper important-links">
            <div className="padding-wrapper">
              <h2 className="headline">
                <ReactSVG src={linkIcon} wrapper="span" className="link-icon" />
                <span>
                  <FormattedMessage id="DASHBOARD_IMPORTANT_LINKS" />
                </span>
              </h2>
              <div className="link-items-wrapper">
                {translatedDashboardLinks.important.map((link, index) => (
                  <DashboardLink
                    key={`${link.title}.${index}`}
                    title={link.title}
                    content={link.content}
                    path={link.path}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        {isConfirmed ? (
          <div className="dashboard-col-wrapper kpi-wrapper">
            <div className="padding-wrapper">
              <h2 className="headline">
                <FormattedMessage id="DASHBOARD_MY_PERFORMANCE" />
              </h2>
              <Kpi />
            </div>
          </div>
        ) : null}
        <div className="dashboard-col-wrapper notifications-wrapper">
          <Scrollbar visible={true}>
            <div className="padding-wrapper">
              {isLargeScreen && hasContest && <Contest />}
              <Notifications
                maxLevel={
                  isMobile ? NOTIFICATION_NOTE : NOTIFICATION_MAINTENANCE
                }
              />
            </div>
          </Scrollbar>
        </div>
      </div>
    </Scrollbar>
  );
}

export default Dashboard;

