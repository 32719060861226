export default {
  CONTEST_LIVECAM_SHOWS: 'Your coins from livecam shows',
  CONTEST_MESSAGES: 'Your coins from messages',
  CONTEST_ITEMS_SOLD: 'Your coins from sold content',
  CONTEST_INFO_LINK_TEXT: 'All info here!',
  CONTEST_UPDATED_TEXT: 'Your ranking is updated every 10 minutes.',
  CONTEST_RANK: 'Rank',
  CONTEST_COINS: 'Coins',
  CONTEST_NO_RANK: 'You`re not in the Top 100 yet',
  CONTEST_END_NO_RANK: 'You didn`t reach the Top 25',

  CONTEST_WIKI_HEADLINE: 'The big LiveCreator Autumn Contest',
  CONTEST_WIKI_INTRO_1:
    'We are looking for the best livecam girls and the best chatters!',
  CONTEST_WIKI_INTRO_2:
    'From <span class="bold">October 1<sup>st</sup> to October 7<sup>th</sup>, 2024</span> we have a <span class="bold">Creator competition</span> that gives you the opportunity to show what you are made of!',

  CONTEST_WIKI_INFO_HEADLINE_1: `What's in it for you?`,
  CONTEST_WIKI_INFO_TEXT_1: `If you make the top of the rankings, you will receive the following bonuses:<br />
    <table class="has-border noHover table300">
       <tr>
          <td>Rank 1</td>
          <td class="align-right">€ 500 bonus</td>
       </tr>
       <tr>
          <td>Rank 2</td>
          <td class="align-right">€ 250 bonus</td>
       </tr>
       <tr>
          <td>Rank 3</td>
          <td class="align-right">€ 150 bonus</td>
       </tr>
       <tr>
          <td>Rank 4 to 10</td>
          <td class="align-right">€ 100 bonus</td>
       </tr>
       <tr>
          <td>Rank 11 to 25</td>
          <td class="align-right">€ 50 bonus</td>
       </tr>
     </table>`,
  CONTEST_WIKI_INFO_TEXT_2: `And the best thing is: <span class='bold'>You can make it to the top of the leaderboard in both categories at once</span>! If you manage to become the best livecam Creator (Rank 1) and the third best chatter (Rank 3), you get a total bonus of 650 EURO!`,
  CONTEST_WIKI_INFO_HEADLINE_2: `How does it work?`,
  CONTEST_WIKI_INFO_TEXT_3: `We will closely monitor and compare the sales of all Creators in the livecams and chat and throughout the contest. If you achieve coin sales in one of the two categories, these are automatically recorded and you are placed in the ranking for the corresponding category.`,
  CONTEST_WIKI_INFO_TEXT_4: `You can always find your current ranking on the homepage. If you are among the 100 best Creators in a category, this will be shown to you separately.`,
  CONTEST_WIKI_INFO_HEADLINE_3: `What you have to do to participate`,
  CONTEST_WIKI_INFO_TEXT_5: `Just be online! 😊 All Creators who log into LiveCreator.com during the promotion period will automatically be entered into the contest. And whoever makes the most coins wins!`,
  CONTEST_WIKI_INFO_TEXT_6: `The following sales are included in the categories:<br />
    <ul class='checklist'>
      <li><span class='bold'>Livecams:</span> all coins from normal, exclusive, voyeur shows and Toycontrol</li>
      <li><span class='bold'>Messages:</span> all coins from sales of paid messages, voice messages, sexicons and community games. Virtual gifts and premium attachments are excluded</li>
    </ul>`,
  CONTEST_WIKI_INFO_TEXT_7: `The contest starts on October 01, 2024 at 00:00 and ends on October 07, 2024 at 24:00. As soon as the contest finishes, the preliminary final result is set. You will then receive your bonus with the payout on October 16, 2024.`,
  CONTEST_WIKI_INFO_TEXT_8: `Compete and show what you're made of!`,
  CONTEST_WIKI_INFO_TEXT_9: `We wish you good luck!`,
};

