import {
  ADD_DIALOG,
  ADD_DIALOGS,
  ADD_ONLINE_STATUS,
  DIALOGS_SORT,
  DIALOG_DUPLICATE_NAME,
  DIALOG_ERROR_LOG,
  DIALOG_PERSIST,
  DIALOG_QUEUE_ADD_ELEMENT,
  DIALOG_QUEUE_ADD_ELEMENTS,
  DIALOG_QUEUE_REMOVE_ELEMENT,
  DIALOG_QUEUE_RESET,
  DIALOG_READ,
  DIALOG_REMOVE,
  DIALOG_SELECT,
  DIALOG_SENDER_KNOWN,
  DIALOG_USER_OFFLINE,
  DIALOG_USER_ONLINE,
  FRIEND_DELETE,
  FRIEND_FRIEND_REQUEST_ANSWER,
  GAMES_STATS_ADD,
  GAME_INVITATION,
  GAME_INVITATION_STATUS,
  GAME_SESSION_STATUS,
  MANAGEMENT_CAM_LOGOUT,
  MANAGEMENT_EVENTS_USER_ADD,
  MANAGEMENT_EVENTS_USER_REMOVE,
  MANAGEMENT_USER_FAVORITE,
  MESSAGE_CHAT_SEND,
  MESSAGE_RECEIVED,
  MESSAGE_SENT,
  NEW_DIALOG,
  SELECT_USER,
  SENDER_LOGOUT,
  SENDER_TYPING,
  UPGRADE_START,
  USER_LOGGED_IN,
  USER_LOGOUT,
  USER_PROFILE_GET,
  USER_STATUS_DELETED,
  USER_UNIGNORE_ID,
} from '../actions/actions';
import {
  IGNORED_MESSAGE,
  MESSAGE_TYPE_SENDER,
  MESSAGE_TYPE_USER,
  SHOW_TYPE_NORMAL,
  SORT_BY_TIME_ASC,
  SORT_BY_TIME_DESC,
} from '../constants';
import { lStorage } from '../services/storage';
import { getUnixTimestamp, user as userUtils } from '../utils';
import crypt from '../utils/crypt';

const initialState = {
  dialogs: [],
  processQueue: [],
  queue: [],
  errorObject: {},
  savedDialogId: '',
  savedUrlUserId: null,
  firstDialogsLoaded: false,
  sort: SORT_BY_TIME_DESC,
};

const sortByTimeDesc = (a, b) => {
  if (a.flags.includes('is_support')) return -1;
  if (b.flags.includes('is_support')) return 1;
  if (a.time < b.time) return 1;
  if (a.time > b.time) return -1;
  return 0;
};
const sortByTimeAsc = (a, b) => {
  if (a.flags.includes('is_support')) return -1;
  if (b.flags.includes('is_support')) return 1;
  if (a.time < b.time) return -1;
  if (a.time > b.time) return 1;
  return 0;
};

let sortingMethodsMap = new Map([
  [SORT_BY_TIME_DESC, sortByTimeDesc],
  [SORT_BY_TIME_ASC, sortByTimeAsc],
]);

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGGED_IN: {
      const newState = { ...state };
      if (action.meta?.userId) {
        newState.savedUrlUserId = action.meta.userId.replace('-', '@');
      } else if (!state.savedDialogId && action.payload?.id) {
        const store = crypt.run(lStorage.getItem(action.payload?.id));
        const oneHour = 3600000;
        const savedDialogId =
          store?.t + oneHour >= Date.now() ? store?.i : null;
        newState.savedDialogId = savedDialogId;
      }
      const sort = lStorage.getItem('sorting');
      if (sort && Array.from(sortingMethodsMap.keys()).includes(sort)) {
        return { ...newState, sort };
      }
      return newState;
    }
    case DIALOG_QUEUE_ADD_ELEMENT: {
      return {
        ...state,
        queue: [
          ...state.queue.filter((el) => el.partner !== action.payload?.partner),
          action.payload,
        ],
      };
    }
    case DIALOG_QUEUE_REMOVE_ELEMENT: {
      return {
        ...state,
        processQueue: [
          ...state.processQueue.filter(
            (el) => el.partner !== action.payload?.partner
          ),
          action.payload,
        ],
        queue: state.queue.filter(
          (el) => el.partner !== action.payload.partner
        ),
      };
    }
    case DIALOG_QUEUE_ADD_ELEMENTS: {
      return {
        ...state,
        queue: [
          ...state.queue,
          ...action.payload.sort(sortingMethodsMap.get(state.sort)),
        ],
      };
    }
    case DIALOG_QUEUE_RESET: {
      return { ...state, queue: [] };
    }
    case DIALOG_ERROR_LOG: {
      return { ...state, errorObject: {} };
    }
    case NEW_DIALOG: {
      if (action.error) {
        if (action.payload?.partner) {
          const newState = { ...state };
          if (action.payload?.error?.message) {
            const errorName = action.payload.error.message;
            if (state.errorObject[errorName]) {
              newState.errorObject[errorName] += 1;
            } else {
              newState.errorObject[errorName] = 1;
            }
          }
          return {
            ...newState,
            processQueue: state.processQueue.filter(
              (el) => el.partner !== action.payload?.partner
            ),
          };
        }
        return state;
      }
      const filteredState = state.dialogs.filter(
        (d) => d.partner !== action.payload?.partner
      );
      let processDialog = {};
      const newProcessQueue = state.processQueue.filter((el) => {
        if (el.partner === action.payload?.partner) {
          processDialog = el;
        }
        return el.partner !== action.payload?.partner;
      });
      const dialog = {
        ...processDialog,
        ...action.payload,
      };
      return {
        ...state,
        processQueue: newProcessQueue,
        dialogs: [...filteredState, dialog].sort(
          sortingMethodsMap.get(state.sort)
        ),
      };
    }
    case ADD_DIALOGS:
      const newDialogs = action.payload;
      const filterDialogs = newDialogs.filter(
        (d) =>
          !state.dialogs.find((el) => d.partner === el?.partner) &&
          !state.processQueue.find((el) => d.partner === el?.partner) &&
          !state.queue.find((el) => d.partner === el?.partner)
      );

      return {
        ...state,
        firstDialogsLoaded: true,
        dialogs: [...state.dialogs, ...filterDialogs].sort(
          sortingMethodsMap.get(state.sort)
        ),
      };
    case ADD_DIALOG: {
      if (action.error) {
        if (action.payload?.partner) {
          const newState = { ...state };
          if (action.payload?.error?.message) {
            const errorName = action.payload.error.message;
            if (state.errorObject[errorName]) {
              newState.errorObject[errorName] += 1;
            } else {
              newState.errorObject[errorName] = 1;
            }
          }
          return {
            ...newState,
            processQueue: state.processQueue.filter(
              (el) => el.partner !== action.payload?.partner
            ),
          };
        }
        return state;
      }
      let isDuplicate = false;
      const filteredState = state.dialogs
        .filter((d) => d.partner !== action.payload?.partner)
        .map((d) => {
          if (d.name?.toLowerCase() === action.payload.name?.toLowerCase()) {
            d.duplicateName = true;
            isDuplicate = true;
          }
          return d;
        });
      let processDialog = {};
      const newProcessQueue = state.processQueue.filter((el) => {
        if (el.partner === action.payload?.partner) {
          processDialog = el;
        }
        return el.partner !== action.payload?.partner;
      });
      let portals = {};
      if (processDialog?.portals) {
        portals = processDialog.portals;
      }
      if (action?.payload?.portals) {
        portals = { ...portals, ...action.payload.portals };
      }
      let profile = processDialog?.profile ? { ...processDialog.profile } : {};
      profile.info = {};

      if (processDialog?.profile?.info) {
        profile.info = { ...processDialog.profile.info };
      }
      if (action?.payload?.profile?.info) {
        profile.info = { ...profile.info, ...action.payload.profile.info };
      }
      profile.info.largeLoaded =
        processDialog?.profile?.info?.largeLoaded ||
        action?.payload?.profile?.info?.largeLoaded;
      const dialog = {
        ...processDialog,
        name: action?.payload?.name || processDialog?.name,
        visible: action.payload.visible,
        portals,
        profile,
        duplicateName: isDuplicate,
      };
      return {
        ...state,
        processQueue: newProcessQueue,
        dialogs: [...filteredState, dialog].sort(
          sortingMethodsMap.get(state.sort)
        ),
      };
    }
    case DIALOG_REMOVE: {
      const id = action.payload.dialogId;
      const filteredState = state.dialogs.filter(
        (d) => d.partner !== id && d.partner.replace('@', '-') !== id
      );
      return { ...state, dialogs: [...filteredState] };
    }
    case DIALOG_SELECT: {
      return {
        ...state,
        dialogs: state.dialogs.map((d) =>
          d.id === action?.payload?.id
            ? { ...d, time: Math.floor(Date.now() / 1000) }
            : d
        ),
        processQueue: state.processQueue.map((d) =>
          d.id === action?.payload?.id
            ? { ...d, time: Math.floor(Date.now() / 1000) }
            : d
        ),
        queue: state.queue.map((d) =>
          d.id === action?.payload?.id
            ? { ...d, time: Math.floor(Date.now() / 1000) }
            : d
        ),
      };
    }
    case SELECT_USER:
      return {
        ...state,
        dialogs: userUtils.selectUser(
          state.dialogs,
          action.payload.userId,
          action.meta?.text
        ),
        processQueue: userUtils.selectUser(
          state.processQueue,
          action.payload.userId
        ),
        queue: userUtils.selectUser(state.queue, action.payload.userId),
        savedUrlUserId: null,
      };
    case USER_UNIGNORE_ID:
      return {
        ...state,
        dialogs: state.dialogs.map((d) => {
          if (d.partner === action.payload) {
            d.flags = d.flags.filter((flag) => flag !== 'ignored');
          }
          return d;
        }),
      };
    case USER_PROFILE_GET:
      return {
        ...state,
        dialogs: state.dialogs.map((d) => {
          if (d.userId === action.payload.userId) {
            d.profile = {
              ...d.profile,
              ...action.payload,
            };
          }
          return d;
        }),
        processQueue: state.processQueue.map((d) => {
          if (d.userId === action.payload.userId) {
            d.profile = {
              ...d.profile,
              ...action.payload,
            };
          }
          return d;
        }),
        queue: state.queue.map((d) => {
          if (d.userId === action.payload.userId) {
            d.profile = {
              ...d.profile,
              ...action.payload,
            };
          }
          return d;
        }),
      };
    case MANAGEMENT_EVENTS_USER_REMOVE:
      if (action?.meta?.userList) {
        // get a list of the visible cam users partnerIds
        const visibleUserIds = userUtils
          .visibleLivecamUser(action.meta.userList) // get the visible users
          .filter((u) => u.userId !== action.payload.userId) // filter out the user that is removed
          .map((u) => userUtils.getPartner(u)); //create the partnerIds

        // set all message users to visible but the visible livecam users
        return {
          ...state,
          dialogs: state.dialogs
            .map((d) => {
              if (visibleUserIds.indexOf(d.partner) < 0) {
                d.visible = true;
              }
              if (
                d.partner === action?.meta?.partner &&
                d.flags.indexOf('unanswered') >= 0
              ) {
                d.flags = [
                  ...d.flags.filter(
                    (flag) => flag !== 'answered' && flag !== 'unanswered'
                  ),
                  'answered',
                ];
              }
              return d;
            })
            .sort(sortingMethodsMap.get(state.sort)),
        };
      }
      return state;
    case UPGRADE_START:
    case MANAGEMENT_EVENTS_USER_ADD:
      const partner =
        action.type === MANAGEMENT_EVENTS_USER_ADD
          ? userUtils.getPartner(action.payload)
          : action.payload.partner;
      return {
        ...state,
        dialogs: state.dialogs
          .map((d) => {
            if (
              partner === d.partner &&
              action.payload.showType === SHOW_TYPE_NORMAL
            ) {
              d.visible = false;
            }
            return d;
          })
          .sort(sortingMethodsMap.get(state.sort)),
      };
    case SENDER_LOGOUT:
    case USER_LOGOUT:
      return initialState;
    case DIALOG_USER_ONLINE:
      return {
        ...state,
        dialogs: state.dialogs.map((d) => {
          if (d.userId === action.payload.user && d.profile?.info?.status) {
            d.profile.info.status = 'online';
          }
          return d;
        }),
      };
    case DIALOG_USER_OFFLINE:
      return {
        ...state,
        dialogs: state.dialogs.map((d) => {
          if (d.userId === action.payload.user && d.profile?.info?.status) {
            d.profile.info.status = 'offline';
          }
          return d;
        }),
      };
    case USER_STATUS_DELETED:
      return {
        ...state,
        dialogs: state.dialogs.map((d) => {
          if (d.userId === action.payload && d.profile?.info?.status) {
            d.profile.info.status = 'deleted';
          }
          return d;
        }),
      };
    case ADD_ONLINE_STATUS:
      return {
        ...state,
        dialogs: state.dialogs.map((d) => {
          if (action.payload.includes(d.userId)) {
            if (!d.flags.includes('online')) d.flags = [...d.flags, 'online'];
          } else {
            if (d.flags.includes('online'))
              d.flags = d.flags.filter((f) => f !== 'online');
          }
          return d;
        }),
      };
    case MESSAGE_SENT:
      return {
        ...state,
        dialogs: state.dialogs
          .map((d) => {
            if (d.partner === action.payload.recipient) {
              if (d.flags.indexOf('noRemoteDialog') >= 0) {
                d.flags = [
                  ...d.flags.filter((flag) => flag !== 'noRemoteDialog'),
                ];
              }
              if (
                d.time < action.payload.time &&
                d.flags.indexOf('unanswered') >= 0
              ) {
                d.time = action.payload.time;
                d.flags = [
                  ...d.flags.filter(
                    (flag) => flag !== 'answered' && flag !== 'unanswered'
                  ),
                  'answered',
                ];
              }
            }
            return d;
          })
          .sort(sortingMethodsMap.get(state.sort)),
      };
    case MESSAGE_RECEIVED: {
      const {
        payload: { type, time, userId, done },
        meta: { isSelected, isCamMessengerRoute },
      } = action;

      return {
        ...state,
        dialogs: state.dialogs
          .map((d) => {
            if (d.userId === userId && d.time < time) {
              if ((done && !isSelected) || (done && !isCamMessengerRoute)) {
                d.read = false;
              }
              d.time = time;
              d.flags = d.flags.filter(
                (flag) =>
                  flag !== 'answered' &&
                  flag !== 'unanswered' &&
                  (flag !== 'noRemoteDialog' || action?.payload?.payload?.ibid)
              );

              if (type === MESSAGE_TYPE_USER) {
                d.flags.push('unanswered');
              }

              if (type === MESSAGE_TYPE_SENDER) {
                d.flags.push('answered');
              }
            }
            return d;
          })
          .sort(sortingMethodsMap.get(state.sort)),
      };
    }
    case MANAGEMENT_CAM_LOGOUT:
      return {
        ...state,
        dialogs: state.dialogs.map((d) => {
          d.visible = true;
          return d;
        }),
      };
    case MESSAGE_CHAT_SEND:
      if (action.error) {
        if (action.payload === IGNORED_MESSAGE) {
          return {
            ...state,
            dialogs: state.dialogs.map((d) => {
              if (d.partner === action?.meta?.user?.partner) {
                d.profile.ignored_by = true;
              }
              return d;
            }),
          };
        }
        return state;
      }
      return {
        ...state,
        dialogs: state.dialogs
          .map((d) => {
            if (d.partner === action?.meta?.user?.partner) {
              d.text = '';
              d.flags = [
                ...d.flags.filter(
                  (flag) => flag !== 'unanswered' && flag !== 'answered'
                ),
                'answered',
              ];
            }
            return d;
          })
          .sort(sortingMethodsMap.get(state.sort)),
      };
    case SENDER_TYPING:
      if (action.payload && action.payload.event) {
        return state;
      }
      return {
        ...state,
        dialogs: userUtils.senderTyping(state.dialogs, action.payload),
      };
    case MANAGEMENT_USER_FAVORITE:
      const userId = action.payload.clientProductId
        ? `${action.payload.clientProductId}-${action.payload.clientCustomerId}`
        : action.payload.userId;

      if (action.error) {
        return {
          ...state,
          dialogs: state.dialogs.map((d) => {
            if (
              d.partner === userId ||
              d.partner === userId.replace('-', '@')
            ) {
              d.requestStarted = false;
            }
            return d;
          }),
        };
      }

      if (action.meta.start) {
        return {
          ...state,
          dialogs: state.dialogs.map((d) => {
            if (
              d.partner === userId ||
              d.partner === userId.replace('-', '@')
            ) {
              d.requestStarted = true;
            }
            return d;
          }),
        };
      }

      return {
        ...state,
        dialogs: state.dialogs.map((d) => {
          if (d.partner === userId || d.partner === userId.replace('-', '@')) {
            if (action.meta.remove) {
              d.flags = [...d.flags.filter((flag) => flag !== 'favorite')];
            } else {
              d.flags = [...d.flags, 'favorite'];
            }
            d.requestStarted = false;
          }
          return d;
        }),
      };
    case FRIEND_FRIEND_REQUEST_ANSWER:
      if (
        action.meta?.start ||
        action.payload?.friendRequestType === 'denied'
      ) {
        return state;
      }
      // Update dialog flags here
      return {
        ...state,
        dialogs: state.dialogs.map((d) => {
          if (
            d.partner === action?.payload.userId ||
            d.partner === action?.payload.userId.replace('-', '@')
          ) {
            d.text = '';
            d.flags = [...d.flags, 'friend'];
          }
          return d;
        }),
      };
    case FRIEND_DELETE:
      if (action.meta) {
        return state;
      }
      return {
        ...state,
        dialogs: state.dialogs.map((d) => {
          if (
            d.partner === action?.payload.id ||
            d.partner === action?.payload.id.replace('-', '@')
          ) {
            d.flags = [...d.flags.filter((flag) => flag !== 'friend')];
          }
          return d;
        }),
      };
    case DIALOGS_SORT:
      const sortingMethod = sortingMethodsMap.get(action.payload);
      return {
        ...state,
        sort: action.payload,
        dialogs: state.dialogs.slice().sort(sortingMethod),
      };
    case DIALOG_DUPLICATE_NAME:
      const index = state.dialogs.findIndex(
        (d) => d.name?.toLowerCase() === action.payload.name?.toLowerCase()
      );

      if (index !== -1) {
        let updatedDialogs = [...state.dialogs];
        updatedDialogs[index].duplicateName = true;
        return {
          ...state,
          dialogs: updatedDialogs,
        };
      }
      return state;

    case DIALOG_READ: {
      const id = action.payload.dialogId;
      return {
        ...state,
        dialogs: state.dialogs.map((d) => {
          if (d.partner === id || d.partner === id.replace('-', '@')) {
            d.read = true;
          }
          return d;
        }),
      };
    }
    case DIALOG_SENDER_KNOWN: {
      const id = action.payload.dialogId;
      return {
        ...state,
        dialogs: state.dialogs.map((d) => {
          if (d.partner === id && d.flags.includes('sender_new')) {
            d.flags = d.flags.filter((el) => el !== 'sender_new');
            d.flags.push('sender_known');
          }
          return d;
        }),
      };
    }
    case GAME_INVITATION: {
      if (!action?.payload?.partner) {
        return state;
      }
      const id = action.payload.partner;
      return {
        ...state,
        dialogs: state.dialogs
          .map((d) => {
            if (d.partner === id && !d.flags.includes('gameInvitation')) {
              d.flags = d.flags.filter(
                (el) => el !== 'gameDecided' && el !== 'noRemoteDialog'
              );
              d.flags.push('gameInvitation');
              d.time = getUnixTimestamp(action.payload.updatedAt);
            }
            return d;
          })
          .sort(sortingMethodsMap.get(state.sort)),
      };
    }
    case GAME_INVITATION_STATUS: {
      if (!action?.payload?.partner) {
        return state;
      }
      const id = action.payload.partner;
      if (
        ['declined', 'timedOut', 'accepted'].includes(action?.payload?.status)
      ) {
        const status = action.payload.status;
        return {
          ...state,
          dialogs: state.dialogs
            .map((d) => {
              if (d.partner === id && d.flags.includes('gameInvitation')) {
                if (status === 'declined') {
                  d.flags = d.flags.filter(
                    (el) =>
                      el !== 'gameInvitation' &&
                      el !== 'gameDecided' &&
                      el !== 'unanswered' &&
                      el !== 'noRemoteDialog'
                  );
                  d.flags.push('answered');
                  d.time = getUnixTimestamp(action.payload.updatedAt);
                } else if (status === 'timedOut') {
                  d.flags = d.flags.filter(
                    (el) =>
                      el !== 'gameInvitation' &&
                      el !== 'gameDecided' &&
                      el !== 'noRemoteDialog'
                  );
                  d.time = getUnixTimestamp(action.payload.updatedAt);
                } else if (status === 'accepted') {
                  d.flags = d.flags.filter(
                    (el) => el !== 'unanswered' && el !== 'noRemoteDialog'
                  );
                  d.flags.push('answered');
                }
              }
              return d;
            })
            .sort(sortingMethodsMap.get(state.sort)),
        };
      }
      return state;
    }
    case GAME_SESSION_STATUS: {
      if (!action?.payload?.partner || !action?.payload?.session) return state;

      const { status, turnId } = action.payload.session;

      const id = action.payload.partner;

      switch (status) {
        case 'decided':
          return {
            ...state,
            dialogs: state.dialogs
              .map((d) => {
                if (d.partner === id && d.flags.includes('game')) {
                  d.flags = d.flags.filter(
                    (el) => !['game', 'gameTurn', 'noRemoteDialog'].includes(el)
                  );
                  d.time = getUnixTimestamp(action.payload.session.updatedAt);
                  d.flags.push('gameDecided');
                }
                return d;
              })
              .sort(sortingMethodsMap.get(state.sort)),
          };
        case 'pending': {
          return {
            ...state,
            dialogs: state.dialogs
              .map((d) => {
                if (d.partner === id) {
                  d.flags = d.flags.filter(
                    (el) =>
                      el !== 'gameInvitation' &&
                      el !== 'gameDecided' &&
                      el !== 'noRemoteDialog'
                  );
                  d.time = getUnixTimestamp(action.payload.session.updatedAt);
                  if (!d.flags.includes('game')) {
                    d.flags.push('game');
                  }
                  if (turnId.replace('-', '@') !== d.partner) {
                    d.flags.push('gameTurn');
                  } else if (turnId.replace('-', '@') === d.partner) {
                    d.flags = d.flags.filter((el) => el !== 'gameTurn');
                  }
                }
                return d;
              })
              .sort(sortingMethodsMap.get(state.sort)),
          };
        }
        default:
          return state;
      }
    }
    case GAMES_STATS_ADD: {
      return {
        ...state,
        dialogs: state.dialogs.map((d) => {
          if (d.partner === action.meta.userId) {
            d.gameStats = { ...action.payload };
          }
          return d;
        }),
      };
    }
    case DIALOG_PERSIST:
      return {
        ...state,
        dialogs: state.dialogs.map((d) => {
          if (d.partner === action.payload) {
            d.persisted = true;
          }
          return d;
        }),
      };
    default:
      return state;
  }
};

export default reducer;
