import { PERSIST_BASE_KEY, PERSIST_SEPERATOR_KEY } from '../../constants';
import socketService from '../../services/socketService';
import { lStorage } from '../../services/storage';
import { clearSystemMessageId, storage } from '../../utils';
import api from '../../utils/api';
import { clearAudioBlobs } from '../../utils/audio';
import {
  logoutSender,
  queue,
  ratatoskr,
  timerFriendRequest,
  timerOnlineStatus,
} from '../';
import { USER_LOGOUT, USER_LOGOUT_FORCED } from '../actions';
// Autonomous = dispatched through the app
//  (e.g., the refresh token might have expired)
export default function (
  isAutonomous = true,
  logoutReason = 1,
  forcedLogout = false
) {
  return async function (dispatch, getState) {
    const { access_token, refresh_token, account } = getState().user;
    clearAudioBlobs();
    dispatch(timerFriendRequest.stop());
    dispatch(queue.clear());
    dispatch({ type: USER_LOGOUT });
    if (lStorage.test()) {
      const keysToStore = [];
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith(PERSIST_BASE_KEY + PERSIST_SEPERATOR_KEY)) {
          keysToStore.push({ [key]: lStorage.getItem(key), key: key });
        }
      });
      storage.clear();
      keysToStore.forEach((obj) => {
        lStorage.setItem(obj.key, obj[obj.key]);
      });
    }

    if (socketService.connected()) {
      socketService.disconnect();
    }
    dispatch(ratatoskr.close());
    dispatch(timerOnlineStatus.stop());

    if (forcedLogout) {
      dispatch({ type: USER_LOGOUT_FORCED });
    }

    if (account?.livecam === 'true') {
      dispatch(logoutSender());
    }

    if (!isAutonomous) {
      await api.delete('/oauth/token', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        data: {
          access_token,
          refresh_token,
        },
      });
    }
    clearSystemMessageId();
    lStorage.setItem('navigationTooltip', 0);
    lStorage.setItem('finaliseTooltip', 0);
    lStorage.setItem('hasUsedDevice', true);
    lStorage.removeItem('parkingCreditsSublink');
    lStorage.removeItem('payoutsSublink');
    lStorage.removeItem('livecam');
    lStorage.removeItem('thirdPartyCookie');
    lStorage.removeItem('referer');
    lStorage.removeItem('userAgent');
    lStorage.removeItem('locale');
    lStorage.removeItem('getParams');
    lStorage.removeItem('parametersDataSet');
  };
}

