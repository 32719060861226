export const SOCKET_EMIT_CALLBACK_TIMEOUT = 5000;
export const SOCKET_OPTIONS = {
  timeout: '6000',
  reconnection: false,
  rememberUpgrade: false,
  multiplex: false,
  rejectUnauthorized: true,
};

export const ALERT_LEVEL_MARK = 'MARK';
export const ALERT_LEVEL_SUCCESS = 'SUCCESS';
export const ALERT_LEVEL_INFO = 'INFO';
export const ALERT_LEVEL_ERROR = 'ERROR';
export const ALERT_LEVEL_NONE = 'NONE';

export const ALERT_TYPE_CLOSE = 'CLOSE';
export const ALERT_TYPE_CONFIRM = 'CONFIRM';
export const ALERT_TYPE_RELOAD = 'RELOAD';
export const ALERT_TYPE_CAM_LOADING = 'CAM_LOADING';
export const ALERT_TYPE_EXCLUSIVE = 'EXCLUSIVE';
export const ALERT_TYPE_VERSION_UPDATE = 'VERSION_UPDATE';
export const ALERT_TYPE_EXCLUSIVE_SENDER = 'EXCLUSIVE_SENDER';
export const ALERT_TYPE_INFO = 'INFO';
export const ALERT_TYPE_FRIEND_REQUEST = 'FRIEND_REQUEST';
export const ALERT_TYPE_WARNING = 'WARNING';
export const ALERT_TYPE_FADING = 'FADING';
export const ALERT_TYPE_SEND_FRIEND_REQUEST = 'SEND_FRIEND_REQUEST';
export const ALERT_TYPE_DELETE_DENIED_REQUEST = 'DELETE_DENIED_REQUEST';
export const ALERT_ACTION_SEND_FRIEND_REQUEST =
  'ALERT_ACTION_SEND_FRIEND_REQUEST';
export const ALERT_ACTION_ANSWER_FRIEND_REQUEST =
  'ALERT_ACTION_ANSWER_FRIEND_REQUEST';
export const ALERT_ACTION_DELETE_FRIEND = 'ALERT_ACTION_DELETE_FRIEND';
export const ALERT_ACTION_DELETE_DIARY_ENTRY =
  'ALERT_ACTION_DELETE_DIARY_ENTRY';
export const ALERT_ACTION_DO_NOT_SHOW_IN = 'ALERT_ACTION_DO_NOT_SHOW_IN';
export const ALERT_ACTION_DELETE_DENIED_FRIEND =
  'ALERT_ACTION_DELETE_DENIED_FRIEND';
export const ALERT_ACTION_IGNORE_USER = 'ALERT_ACTION_IGNORE_USER';
export const ALERT_ACTION_UNIGNORE_USER = 'ALERT_ACTION_UNIGNORE_USER';
export const ALERT_ACTION_USER_LOGOUT = 'ALERT_ACTION_USER_LOGOUT';

export const ALERT_GONE_TIMEOUT = 3000;
export const MOTTO_MAX_LENGTH = 40;
export const MOTTO_MAX_TIME = 120000;

export const USER_MODE_MOBILE = 'USER_MODE_MOBILE';

export const SHOW_TYPE_NORMAL = 'normal';
export const SHOW_TYPE_FREE = 'free';
export const SHOW_TYPE_VOYEUR = 'voyeur';
export const SHOW_TYPE_EXCLUSIVE = 'exclusive';

export const ROOM_TYPE_NORMAL = 'normal';
export const ROOM_TYPE_NORMAL_WITH_VOYEUR = 'normalWithVoyeur';
export const ROOM_TYPE_EXCLUSIVE = 'exclusive';
export const ROOM_TYPE_EVENT = 'event';

export const CHAT_LAYOUT_MULTI = 'multi';
export const CHAT_LAYOUT_SINGLE = 'single';

export const ORIGIN_EU = 'EU';
export const ORIGIN_EXCLUSIVE_PRIORITY = [ORIGIN_EU];

export const EXCERPT_TEXT = ' ...';
export const PERSIST_BASE_KEY = 'lcrwss';
export const PERSIST_SEPERATOR_KEY = ':::';

export const LOGMESSAGE_LEVEL_DEBUG = 'DEBUG';
export const LOGMESSAGE_LEVEL_INFO = 'INFO';
export const LOGMESSAGE_LEVEL_WARN = 'WARN';
export const LOGMESSAGE_LEVEL_ERROR = 'ERROR';

export const USER_ACTION_BUTTON_TYPE_EXCLUSIVE = 'exclusive';
export const USER_ACTION_BUTTON_TYPE_INFO = 'info';
export const USER_ACTION_BUTTON_TYPE_MIC = 'mic';
export const USER_ACTION_BUTTON_TYPE_MUTED = 'muted';
export const USER_ACTION_BUTTON_TYPE_NOTE = 'note';
export const USER_ACTION_BUTTON_TYPE_KICK = 'kick';

export const STREAMING_STOPPED = 'STREAMING_STOPPED';
export const STREAMING_IS_STARTING = 'STREAMING_IS_STARTING';
export const STREAMING_STARTED = 'STREAMING_STARTED';
export const STREAMING_RECONNECTING = 'STREAMING_RECONNECTING';

export const MANAGEMENT_LOGGED_OUT = 'MANAGEMENT_LOGGED_OUT';
export const MANAGEMENT_CONNECT_STARTED = 'MANAGEMENT_CONNECT_STARTED';
export const MANAGEMENT_LOGIN_STARTED = 'MANAGEMENT_LOGIN_STARTED';
export const MANAGEMENT_LOGGED_IN = 'MANAGEMENT_LOGGED_IN';
export const MANAGEMENT_LOGOUT_STARTED = 'MANAGEMENT_LOGOUT_STARTED';
export const EXCLUSIVE_MAX_TICK = 30;

export const DEFAULT_CHAT_FONT_SIZE = 16;
export const POSSIBLE_CHAT_FONT_SIZES = [14, 16, 18, 20, 22, 24, 26, 28];
export const STREAMING_SERVER_TYPE = 'sender';
export const STREAMING_SERVER_TYPE_CAM2CAM = 'cam2camSender';

export const MESSAGE_TYPE_USER = 'MESSAGE_TYPE_USER';
export const MESSAGE_TYPE_SENDER = 'MESSAGE_TYPE_SENDER';
export const MESSAGE_TYPE_SENDER_2ALL = 'MESSAGE_TYPE_SENDER_2ALL';
export const MESSAGE_TYPE_SYSTEM = 'MESSAGE_TYPE_SYSTEM';
export const MESSAGE_TYPE_RESEND = 'MESSAGE_TYPE_RESEND';

export const COOKIE_BASENAME = 'wss';
export const COOKIE_EXPIRES_DAYS = 30;
export const CAM_LOGOUT_TIMER_TIMEOUT = 6000;
export const CAM_VIDEO_MIN_HEIGHT_PERCENT = 15;
export const ABORT_ERROR_NAME = 'AbortError';
export const ACTIVE_NAV_ITEM_CLASS = 'active';
export const CHECKUSER_INTERVAL = 5000;
export const CHECKUSER_INTERVAL_EVENT = 1200000;
export const CHECK_STREAM_TIMEOUT = 5000;
export const LOG_HIGH_BANDWIDTH = 4000;
export const MAX_ZEROBYTES_RECONNECT_TRIES = 3;
export const RATATOSKR_SERVICE_URL = `${process.env.REACT_APP_RATATOSKR_SOCKET_URL}/as`;
export const RATATOSKR_TIME_FRAME = 300000; //for testing purposes change this to RATATOSKR_TIME_FRAME = 300000000;
export const SOCKET_SERVICE_URL = process.env.REACT_APP_MESSAGE_SOCKET_URL;
export const SOCKET_SERVICE_OPTIONS = {
  reconnection: true,
  autoConnect: true,
  transports: ['websocket'],
};
export const SOCKET_SERVICE_STEP_NOT_CONNECTED =
  'SOCKET_SERVICE_STEP_NOT_CONNECTED';
export const SOCKET_SERVICE_STEP_STARTED = 'SOCKET_SERVICE_STEP_STARTED';
export const SOCKET_SERVICE_STEP_RECONNECTING =
  'SOCKET_SERVICE_STEP_RECONNECTING';
export const SOCKET_SERVICE_STEP_CONNECTED = 'SOCKET_SERVICE_STEP_CONNECTED';

export const MESSAGE_LIMIT_ERROR = 'messages_limit_reach';
export const MESSAGE_LIMIT_NO_DIALOG = 'message_limit_no_dialog';
export const OFFLINE_DAYS_LIMIT = 'offline_for_too_long';
export const PAYTTACHMENT_ALREADY_RECEIVED_ERROR =
  'recipient_already_received_payttachment';
export const PAYTTACHMENTS_DISABLED = 'payttachments_disabled';
export const PAYTTACHMENTS_DISABLED_FOR_USER =
  'payttachments_disabled_for_user';
export const PAYTTACHMENT_NOT_FOUND = 'no_payttachment_found';
export const CONTACT_TOO_OLD = 'contact_too_old';
export const NO_EXISTING_CONVERSATION = 'no_existing_conversation';
export const NO_EXISTING_CONVERSATION_GAME_TIMEOUT =
  'no_existing_conversation_game_timeout';
export const INVALID_MESSAGE = 'invalid';
export const IGNORING_MESSAGE = 'ignoring';
export const IGNORED_MESSAGE = 'ignored';

export const WARNING_FPS = 'WARNING_FPS';
export const CRITICAL_FPS = 'CRITICAL_FPS';

export const SORT_BY_TIME_DESC = 'SORT_BY_TIME_DESC';
export const SORT_BY_TIME_ASC = 'SORT_BY_TIME_ASC';

export const PAGINATION_PAGE_SIZE = 25;
export const COMMENTS_PAGINATION_SIZE = 10;
export const GALLERY_PAGINATION_SIZE = 24;
export const NEWSLETTER_PAGINATION_SIZE = 20;
export const FMOTD_PAGINATION_SIZE = 10;
export const FMOTD_MOBILE_PAGINATION_SIZE = 5;
export const BACK = 'BACK';
export const FORWARD = 'FORWARD';

export const REQUESTED = 'requested';
export const SELF = 'self';
export const FRIEND = 'friend';
export const NONE = 'none';
export const DENIED = 'denied';
export const DENIED_BY_PARTNER = 'deniedByPartner';

export const ACTIVITY_TYPE_PROFILE_VISIT = 'ACTIVITY_TYPE_PROFILE_VISIT';
export const ACTIVITY_TYPE_CUSTOMER_LOGIN = 'ACTIVITY_TYPE_CUSTOMER_LOGIN';
export const ACTIVITY_TYPE_FRIEND_LOGIN = 'ACTIVITY_TYPE_FRIEND_LOGIN';
export const ACTIVITY_TYPE_FRIEND_REQUEST = 'ACTIVITY_TYPE_FRIEND_REQUEST';
export const ACTIVITY_TYPE_CONTENT_PURCHASE = 'ACTIVITY_TYPE_CONTENT_PURCHASE';

export const STREAMING_TIME_OFFSET = 0;

export const NOTIFICATION_ALL = 'all';
export const NOTIFICATION_TIP = 'tip';
export const NOTIFICATION_NOTE = 'note';
export const NOTIFICATION_WARNING = 'warning';
export const NOTIFICATION_MAINTENANCE = 'maintenance';
export const NOTIFICATION_DEACTIVATION = 'deactivation';
export const NOTIFICATION_LEVELS = [
  NOTIFICATION_DEACTIVATION,
  NOTIFICATION_MAINTENANCE,
  NOTIFICATION_WARNING,
  NOTIFICATION_NOTE,
  NOTIFICATION_TIP,
];
export const VERSION_CHECK_TIMEOUT = 600000;
export const MAX_VOICE_RECORDING_TIME_SEC = 20; // needs to be a number below 60

export const SUPPORTED_ATTACHMENT_TYPES = [
  '.jpg',
  '.jpeg',
  '.jpe',
  '.png',
  '.mp4',
  '.mov',
];

export const CONTEST_PREVIEW = 'preview';
export const CONTEST_ACTIVE = 'active';
export const CONTEST_FINISHED = 'finished';

export const CONTEST_TEMPLATE = 'contestTemplate';
export const CONTEST_TEMPLATES = {
  summer2023: CONTEST_TEMPLATE,
  summer2024: CONTEST_TEMPLATE,
  autumn2024: CONTEST_TEMPLATE,
};
